import React from 'react'
import { useLocation } from 'react-router-dom'

const step = ['/newpage', '/import', '']

export default function Steps() {
    const { pathname } = useLocation()

    return (
        <div className='flex items-center justify-center gap-2 px-6'>
            {step.map((item, index) => (
                <button
                    key={index}
                    className={`text-sm ${item === pathname
                        ? 'text-[rgb(234,194,73)] border-b-2 border-b-[rgb(234,194,73)]'
                        : 'text-white/50'}`}
                    disabled={item !== pathname}
                >
                    Step {index + 1}
                </button>
            ))}
        </div>
    )
}
