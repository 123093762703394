import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaArrowLeft } from "react-icons/fa";

export default function Navbr() {
    const navigate = useNavigate()

    return (
        <nav className='fixed top-0 left-0 w-full p-[15px] h-[68px] flex items-center justify-center bg-[rgb(28,25,25)] z-10'>
            <button
                className='absolute top-1/2 -translate-y-1/2 left-[15px] flex gap-2 items-center text-white no-underline'
                onClick={() => navigate(-1)}
            >
                <FaArrowLeft color='white' />
                <span>Back</span>
            </button>
            <h3 className='text-center text-sm select-none'>Restore from mnemonics</h3>
        </nav>
    )
}
