import { Link } from 'react-router-dom';

function App() {
  return (
    <div className='w-screen h-screen p-5'>
      <div className='h-full flex items-center justify-center overflow-x-hidden overflow-y-auto'>
        <div className='w-[285px]'>
          {/* Header */}
          <header className='flex items-center justify-center gap-2'>
            <img src="/wallet-logo.png" alt="" className='w-12 h-12' />
            <h1 className="text-2xl select-none" translate='no'>UNISAT</h1>
          </header>

          {/* Main */}
          <main className='mt-10 flex flex-col gap-5'>
            {/* description */}
            <p className="text-xs text-white/50 text-center select-none">Inscribe and store your inscriptions in the world's first Open <a href="./sitemap.xml">source</a> Chrome wallet for Ordinals!</p>
            {/* Create new wallet */}
            <Link
              className='h-10 rounded bg-[rgb(253,224,71)] text-sm text-black flex items-center justify-center no-underline'
              to={'/create'}
            >
              Create new wallet
            </Link>
            {/* Already have a wallet */}
            <Link
              className='h-9 rounded bg-[rgb(42,38,38)] text-white text-sm flex items-center justify-center no-underline'
              to={'/newpage'}
            >
              I already have a wallet
            </Link>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
