import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'

const wallet = ['UniSat Wallet', 'Sparrow Wallet', 'Xverse Wallet', 'Ordinals Wallet', 'Other Wallet']

export default function NewPage() {
    return (
        <>
            <Header />
            <div className='px-6 pb-6 mt-[32px] flex flex-col gap-3'>
                <p className='text-lg text-center'>Choose a wallet you want to restore from</p>

                {wallet.map((item, index) => (
                    <Link
                        key={index}
                        to={'/import'}
                        className='w-full bg-[rgb(42,38,38)] rounded min-h-9 flex items-center justify-center px-3 text-sm text-white no-underline'
                    >
                        {item}
                    </Link>
                ))}
            </div>
        </>
    )
}
