import React from 'react'
import Navbr from './Navbr'
import Steps from './Steps'

export default function Header() {
    return (
        <>
            <Navbr />
            <div className='mt-[68px]'></div>
            <Steps />
        </>
    )
}
